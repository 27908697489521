<template>
  <div class="py-4 bg-expandable" :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
    <Table
      :columns="columns"
      :data="items"
      :row-selection.sync="rowSelection"
      row-click-selection-toggle
      :hover="false"
      small
      custom-class="items-table"
    >
      <template #cell-orderDate="{ rowData: { orderDate } }">
        <p>{{ formatDate(orderDate) }}</p>
      </template>
      <template #cell-differenceAmount="{ rowData: { differenceAmount } }">
        <p>{{ formatMoney(differenceAmount) }}</p>
      </template>
      <template #cell-details="{ rowData: { details, solved } }">
        <span class="text-truncate">
          <template v-if="solved">
            <span>
              {{ $t(`modals.issues.itemsTable.details.solvedDifference`) }}
            </span>
          </template>
          <span :class="{ 'text-decoration-line-through': solved }">
            {{ details }}
          </span>
        </span>
      </template>
    </Table>
  </div>
</template>

<script>
import { isNil } from 'ramda';

import i18n from '@/imports/startup/client/i18n';
import { percent } from '@/locale/numberConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { Table } from '@/modules/core';

import { formatDate, formatMoney } from '../tools/formatters';

export const TABLE_HEADER = {
  DATE: 'orderDate',
  DOCUMENT_NUMBER: 'documentNumber',
  SKU: 'sku',
  PRODUCT: 'product',
  TERMS: 'terms',
  DIFFERENCE_TYPE: 'differenceType',
  DIFFERENCE_AMOUNT: 'differenceAmount',
  DETAILS: 'details',
};

const formatPercent = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, percent)
    : null;
};

const formatQuantity = (number) => {
  return typeof number === 'number' && !Number.isNaN(number) ? Number(number).toLocaleString(i18n.locale) : null;
};

const getOrderItemTerms = (itemData) => {
  const { price, quantity, discount } = itemData;
  const terms = {};
  const texts = [];
  if (!isNil(price)) {
    terms.price = formatMoney(price);
  } else {
    terms.price = i18n.t('commons.unknown');
  }
  if (!isNil(discount)) {
    terms.discount = formatPercent(discount);
  }
  if (!isNil(quantity)) {
    terms.quantity = formatQuantity(quantity);
  }

  if (terms.quantity) {
    texts.push(terms.quantity);
  }
  if (terms.discount) {
    texts.push(`(${terms.price} + ${terms.discount})`);
  } else {
    texts.push(terms.price);
  }

  // do not change me to an english x - it messing all the text direction
  return texts.join(' ✕ ');
};

const getPriceDetails = (itemData) => {
  const {
    difference: { price, discount },
    parentContext,
  } = itemData;
  let billed;
  let ordered;
  if (price && discount) {
    billed = `${formatMoney(price.supplierValue)} + ${formatPercent(discount.supplierValue)}`;
    ordered = `${formatMoney(price.customerValue)} + ${formatPercent(discount.customerValue)}`;
  } else {
    if (price) {
      billed = formatMoney(price.supplierValue);
      ordered = formatMoney(price.customerValue);
    }
    if (discount) {
      billed = formatPercent(discount.supplierValue);
      ordered = formatPercent(discount.customerValue);
    }
  }
  if (billed && ordered)
    return i18n.t(`modals.issues.itemsTable.details.${parentContext}.amount`, {
      billed,
      ordered,
    });
  return null;
};

const getQuantityDetails = (itemData) => {
  const {
    difference: { quantity },
    parentContext,
    supplier,
    customer,
  } = itemData;

  return i18n.t(`modals.issues.itemsTable.details.${parentContext}.quantity`, {
    billed: formatQuantity(quantity.supplierValue),
    ordered: formatQuantity(quantity.customerValue),
    supplier,
    customer,
  });
};

export const createItemsData = (item) => ({
  [TABLE_HEADER.DATE]: item.orderDate,
  [TABLE_HEADER.DOCUMENT_NUMBER]: item.documentNumber,
  [TABLE_HEADER.SKU]: item.product?.sku,
  [TABLE_HEADER.PRODUCT]: item.product?.name,
  [TABLE_HEADER.TERMS]: getOrderItemTerms(item),
  [TABLE_HEADER.DIFFERENCE_TYPE]: i18n.t('modals.issues.itemsTable.diffType', {
    type: i18n.t(`modals.issues.itemsTable.types.${item.difference.type}`),
  }),
  [TABLE_HEADER.DIFFERENCE_AMOUNT]: item.difference.amount,
  [TABLE_HEADER.DETAILS]: item.difference.type === 'pricing' ? getPriceDetails(item) : getQuantityDetails(item),
  supplier: item.supplier,
  customer: item.customer,
  quantity: item.quantity,
  difference: item.difference ?? null,
  parentContext: item.parentContext,
  solved:
    item.difference.type === 'pricing'
      ? item.difference.netPrice.solved ?? false
      : item.difference.quantity.solved ?? false,
});

export default {
  components: { Table },
  props: {
    items: { type: Array, required: true },
    selectedEvent: { type: Boolean, default: false },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      return formatToCurrency(value) ?? '-';
    };

    return { formatMoney };
  },
  data() {
    return {
      rowSelection: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('modals.issues.itemsTable.table.header.date'),
          key: TABLE_HEADER.DATE,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.documentNumber'),
          key: TABLE_HEADER.DOCUMENT_NUMBER,
          minWidth: '130px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.sku'),
          key: TABLE_HEADER.SKU,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.product'),
          key: TABLE_HEADER.PRODUCT,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.terms'),
          key: TABLE_HEADER.TERMS,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.differenceType'),
          key: TABLE_HEADER.DIFFERENCE_TYPE,
          minWidth: '150px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.totalDifference'),
          key: TABLE_HEADER.DIFFERENCE_AMOUNT,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.details'),
          key: TABLE_HEADER.DETAILS,
          minWidth: '420px',
        },
      ];
    },
  },
  watch: {
    selectedEvent: {
      handler(bool) {
        if (bool && !this.rowSelection.length) this.rowSelection = this.items.map((_, index) => index);
        else if (!bool && this.rowSelection.length) this.rowSelection = [];
      },
      immediate: true,
    },
    rowSelection(selected) {
      this.$emit('selection-changed', selected);
    },
  },
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.bg-expandable {
  background: #f6f7f9;
}

::v-deep .items-table {
  th,
  td {
    background: #f6f7f9;
  }

  tr {
    td {
      cursor: initial !important;
      background: #f6f7f9 !important;
    }
  }
}
</style>
