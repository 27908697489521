<template>
  <div>
    <h3 class="mb-4">
      {{ $t('delivery.receivingReturningDocumentsOverview') }}
    </h3>
    <Table border rounded :data="tableData" :columns="columns" :hover="false" show-index>
      <template #cell-document="{ rowData: { document, documentId } }">
        <a href="" @click.prevent.stop="$emit('preview-document', documentId)">{{ document }}</a>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table } from '@/modules/core';

import { formatDate, formatToFloat } from '../tools/formatters';

const TABLE_HEADERS = {
  DOCUMENT: 'document',
  DELIVERED_ON: 'deliveredOn',
  ITEM_COUNT: 'itemCount',
  TOTAL_AMOUNT: 'totalAmount',
};

export default {
  components: { Table },
  props: { deliveryTasks: { type: Array, default: () => [] } },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.document'),
          key: TABLE_HEADERS.DOCUMENT,
        },
        {
          header: this.$t('delivery.headers.deliveredOn'),
          key: TABLE_HEADERS.DELIVERED_ON,
        },
        {
          header: this.$t('delivery.headers.itemCount'),
          key: TABLE_HEADERS.ITEM_COUNT,
        },
        {
          header: this.$t('delivery.headers.totalAmountInclVAT'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
      ];
    },
    tableData() {
      return this.deliveryTasks.map((task) => {
        return {
          [TABLE_HEADERS.SUPPLIER]: task.delivery.document.supplier.name,
          [TABLE_HEADERS.DOCUMENT]: `${this.$i18n.t(
            `document.exports.schema.type.shortName.${task.delivery.document.type}`
          )} ${task.delivery.document.documentNumber}`,
          [TABLE_HEADERS.DELIVERED_ON]: formatDate(task.delivery.document.issueDate),
          [TABLE_HEADERS.ITEM_COUNT]: task.delivery.document.items.length,
          [TABLE_HEADERS.TOTAL_AMOUNT]: formatToFloat(task.delivery.document.totalAmount) ?? '-',
          documentId: task.delivery.document.id,
          supplierId: task.delivery.document.supplier.id,
        };
      });
    },
  },
};
</script>

<style></style>
