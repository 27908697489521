<template>
  <div>
    <h3 class="mb-4">{{ $t('modals.issues.billings.title') }}</h3>
    <Table
      :columns="columns"
      :data="tableData"
      :row-selection.sync="rowSelection"
      row-click-selection-toggle
      :expandable.sync="expandableRows"
      show-index
      rounded
      border
    >
      <template #cell-date="{ rowData: { date } }">
        <p>{{ formatDate(date) }}</p>
      </template>

      <template #cell-documentNumber="{ rowData: { documentNumber, document } }">
        <a href="" @click.prevent.stop="$emit('preview-document', document.id)">{{ documentNumber }}</a>
      </template>

      <template #cell-netAmount="{ rowData: { netAmount } }">
        <p>{{ formatMoney(netAmount) }}</p>
      </template>
      <template #cell-taxAmount="{ rowData: { taxAmount } }">
        <p>{{ formatMoney(taxAmount) }}</p>
      </template>

      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        <p>{{ formatMoney(totalAmount) }}</p>
      </template>

      <template #cell-status="{ rowData: { status } }">
        <Status :status="status" />
      </template>

      <template #cell-details="{ rowData: { details } }">
        <div v-if="details">
          <p>{{ details.text }}</p>
          <p>{{ details.info }}</p>
        </div>
      </template>
      <template #expandable-content="{ rowData: { orderItems, id }, rowIndex }">
        <ItemsTable
          :key="id"
          :items="orderItems"
          :selected-event="rowSelection.includes(rowIndex)"
          @selection-changed="handleRowInnerSelection(rowIndex, $event)"
        />
      </template>
    </Table>
  </div>
</template>

<script>
import { clone, flatten, isNil, omit, uniq } from 'ramda';

import { Table } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { RECONCILIATION_STATUSES } from '@/modules/reconciliation';
import { getBillingStatus } from '@/modules/billing/status';

import { formatDate, formatToFloat } from '../tools/formatters';
import ItemsTable from './ItemsTable';
import { createItemsData } from './ItemsTable.vue';

const TABLE_HEADER = {
  DATE: 'date',
  DOCUMENT_NUMBER: 'documentNumber',
  NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  DETAILS: 'details',
};

export default {
  components: { Table, ItemsTable, Status: () => import('@/modules/reconciliation/reconciliationStatus/Status') },
  props: {
    billings: { type: Array, default: () => [] },
    missing: { type: Array, default: () => [] },
    expandedBillingId: { type: String, required: false, default: null },
  },
  setup() {
    const { isAdmin } = useUser();
    const { formatToCurrency } = useCurrency();

    return {
      formatToCurrency,
      isAdmin,
    };
  },
  data() {
    return {
      expandableRows: {},
      rowSelection: [],
      selectedItemsIndexesByRowIndex: {},
      selectedData: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('modals.issues.billings.table.header.date'),
          key: TABLE_HEADER.DATE,
          minWidth: '130px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.documentNumber'),
          key: TABLE_HEADER.DOCUMENT_NUMBER,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.netAmount'),
          key: TABLE_HEADER.NET_AMOUNT,
          minWidth: '160px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.taxAmount'),
          key: TABLE_HEADER.TAX_AMOUNT,
          minWidth: '140px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.totalAmount'),
          key: TABLE_HEADER.TOTAL_AMOUNT,
          minWidth: '160px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.status'),
          key: TABLE_HEADER.STATUS,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.billings.table.header.details'),
          key: TABLE_HEADER.DETAILS,
          minWidth: '220px',
        },
      ];
    },

    tableData() {
      return this.prepareBilledTableData(this.billings, this.missing);
    },
  },
  watch: {
    rowSelection(newSelected) {
      const newSelectedItemsIndexesByRowIndex = {};

      newSelected.forEach((rowIndex) => {
        const rowData = this.tableData[rowIndex];
        if (!this.selectedItemsIndexesByRowIndex[rowIndex])
          newSelectedItemsIndexesByRowIndex[rowIndex] = rowData.orderItems.map((_, index) => index);
        else newSelectedItemsIndexesByRowIndex[rowIndex] = this.selectedItemsIndexesByRowIndex[rowIndex];
      });

      this.selectedItemsIndexesByRowIndex = newSelectedItemsIndexesByRowIndex;
    },

    selectedItemsIndexesByRowIndex(newSelectedItemsIndexesByRowIndex) {
      const rowIndexesToUpdate = uniq([
        ...this.rowSelection,
        ...Object.keys(newSelectedItemsIndexesByRowIndex).map((indexKey) => Number(indexKey)),
      ]);

      this.selectedData = rowIndexesToUpdate.map((rowIndex) => {
        const rowData = clone(this.tableData[rowIndex]);
        const selectedItemsIndexes = newSelectedItemsIndexesByRowIndex[rowIndex] ?? [];
        const newOrderItems = rowData.orderItems.filter((_, index) => selectedItemsIndexes.includes(index));
        return {
          ...rowData,
          orderItems: newOrderItems,
        };
      });
    },
    tableData(value) {
      if (this.expandedBillingId) {
        const selectedBillingIndex = value.findIndex((billing) => billing.id === this.expandedBillingId);
        if (value[selectedBillingIndex].expandable) {
          this.expandableRows = { ...this.expandableRows, [selectedBillingIndex]: true };
        }
      }
    },
    selectedData(data) {
      this.$emit('selected-data', data);
    },
  },
  methods: {
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    formatDate,
    handleRowInnerSelection(rowIndex, selectedItemsIndexes) {
      if (selectedItemsIndexes.length) {
        this.selectedItemsIndexesByRowIndex = {
          ...this.selectedItemsIndexesByRowIndex,
          [rowIndex]: selectedItemsIndexes,
        };
      } else {
        this.selectedItemsIndexesByRowIndex = omit([rowIndex], this.selectedItemsIndexesByRowIndex);
      }

      this.$nextTick(() => {
        if (!this.rowSelection.includes(rowIndex) && selectedItemsIndexes.length)
          this.rowSelection = [...this.rowSelection, rowIndex];
      });
    },
    getDiffs(diff, pricing) {
      return {
        ...omit(pricing ? ['quantity'] : ['price', 'discount'], diff),
        amount: pricing ? diff.pricingAmount : diff.quantityAmount,
        type: pricing ? 'pricing' : 'quantity',
      };
    },
    createOrderItem(order, diff, isPricingDiff) {
      const item = order.products.find((oi) => oi.id === diff.customerItemId);
      const orderItem = {
        orderDate: order.date,
        documentNumber: `${this.$i18n.t(`document.exports.schema.type.shortName.${order.document.type}`)} ${
          order.document.documentNumber
        }`,
        product: diff.product,
        difference: this.getDiffs(diff, isPricingDiff),
        parentContext: 'billed',
      };
      if (item)
        return {
          ...item,
          ...orderItem,
        };
      else
        return {
          quantity: 0,
          discount: diff.discount?.supplierValue,
          price: diff.price?.supplierValue,
          ...orderItem,
        };
    },
    getDetails(billing, missing) {
      if (billing.netAmount < 0) {
        const amountMatched = billing.orderLinks.reduce((total, link) => total + (link.amount || 0), 0);
        if (billing.netAmount - amountMatched !== 0)
          return {
            text: this.$i18n.t('modals.issues.details.unmatched'),
            info: `${this.formatToCurrency(Math.abs(billing.netAmount))} / ${this.formatToCurrency(
              Math.abs(amountMatched)
            )}`,
          };
      }

      if (billing.imbalances.length) {
        const totalImbalance = billing.imbalances.reduce(
          (totalImbalance, imbalance) => totalImbalance + imbalance.billedAmount - imbalance.orderedAmount,
          0
        );

        return {
          text: this.$i18n.tc('modals.issues.details.imbalance', billing.orderLinks.length),
          info: this.$i18n.t('modals.issues.details.totalImbalance', {
            totalImbalance: this.formatToCurrency(totalImbalance),
          }),
        };
      }

      if (missing.length) {
        return {
          text: this.$i18n.tc('modals.issues.details.missing', missing.length),
        };
      }
    },
    prepareBilledTableData(billings, missingDocuments) {
      const orderToBillings = billings.reduce((acc, billing) => {
        for (const orderLink of billing.orderLinks) {
          if (isNil(orderLink.order)) continue;
          if (!(orderLink.order.id in acc)) {
            acc[orderLink.order.id] = [];
          }
          acc[orderLink.order.id].push({
            billingId: billing.id,
            documentType: document.type,
          });
        }
        return acc;
      }, {});
      return billings
        .map((billing) => {
          const ordersItems = billing.orderLinks.reduce((items, { order }) => {
            if (order) {
              const itemsWithDifferences = (order.differences || []).map((diff) => {
                const orderLinkedToNoneCreditNoteBilling =
                  orderToBillings[order.id].findIndex(({ billingId, documentType }) => {
                    return billingId !== billing.id && documentType !== 'creditNote';
                  }) !== -1;
                if (billing.document.type === 'creditNote' && orderLinkedToNoneCreditNoteBilling) return [];
                const diffs = [];
                const pricingDiffExists = diff.price || diff.discount;
                if (pricingDiffExists) diffs.push(this.createOrderItem(order, diff, pricingDiffExists));
                if (diff.quantity) diffs.push(this.createOrderItem(order, diff));
                return diffs;
              });
              return [...items, ...flatten(itemsWithDifferences)];
            }
            return items;
          }, []);

          const missingDocumentsRelatedToBilling = missingDocuments.filter(
            (m) => m.parentDocumentId === billing.document.id
          );
          const isCredit = billing.netAmount < 0;

          const billingStatus =
            missingDocumentsRelatedToBilling.length && !isCredit
              ? RECONCILIATION_STATUSES.NOT_APPROVED
              : getBillingStatus(billing);

          return {
            id: billing.id,
            [TABLE_HEADER.DATE]: billing.date,
            [TABLE_HEADER.DOCUMENT_NUMBER]: billing.document
              ? `${this.$i18n.t(`document.exports.schema.type.shortName.${billing.document.type}`)} ${
                  billing.document.documentNumber
                }`
              : this.$i18n.t('modals.issues.balanceBilling'),
            [TABLE_HEADER.NET_AMOUNT]: formatToFloat(billing.netAmount),
            [TABLE_HEADER.TAX_AMOUNT]: formatToFloat(billing.taxAmount),
            [TABLE_HEADER.TOTAL_AMOUNT]: formatToFloat(billing.totalAmount),
            [TABLE_HEADER.STATUS]: billingStatus,
            [TABLE_HEADER.DETAILS]: this.getDetails(billing, missingDocumentsRelatedToBilling),
            orderItems: ordersItems.map(createItemsData),
            document: billing.document,
            expandable: ordersItems.length,
            expandableCustomClass: 'p-0 bg-light',
          };
        })
        .sort((a, b) => a.date - b.date);
    },
  },
};
</script>
